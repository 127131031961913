<template>
  <div>
    <div>
      <b-card-actions
        title="Filters"
        action-collapse
        collapsed
      >
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Created At"
              label-for="v-ref-no"
            >
              <div class="position-relative">
                <flat-pickr
                  id="v-ref-no"
                  v-model="filters['created_at']"
                  :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                  class="form-control"
                  placeholder="Search"
                />
                <div
                  v-if="filters['created_at']"
                  class="flatpickr-cancel"
                  @click="clearDate('created_at')"
                >
                  <feather-icon icon="XIcon" />
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
    <div>
      <b-card
        no-body
      >
        <b-overlay
          id="overlay-background"
          blur="10px"
          opacity="0.30"
          rounded="sm"
          variant="light"
        >
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            show-empty
          />
          <template #empty>
            <TableDataFetching
              :rows="rows"
              :data-loading="dataLoading"
            />
          </template>
          <template #cell(status_name)="data">
            <div class="d-flex align-items-center">
              <b-avatar
                size="32"
                variant="`light-${data.item.color}`"
              >
                <feather-icon
                  size="24"
                  :icon="data.item.icon"
                />
              </b-avatar>
              <div>
                <div class="font-weight-bolder ml-1">
                  {{ data.item.status_name }}
                </div>
              </div>
            </div>
          </template>
        </b-overlay>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-sm-start"
            >
              <label>Per page</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-1 width-100 mr-1"
              />
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              md="9"
              class="d-flex align-items-center justify-content-sm-end"
            >
              <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
              <b-pagination
                v-model="page"
                :total-rows="(meta.total)? meta.total : 0"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BPagination,
  BRow,
  BTable,
  VBModal,
  BAvatar,
  BOverlay,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import Filter from '@core/mixins/filter'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ReportRepository = RepositoryFactory.get('report')

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BCardActions,
    BAvatar,
    'v-select': vSelect,
    flatPickr,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BOverlay,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      filters: {},
      dataLoading: false,
      sort: '',
      perPage: 20,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      page: 1,
      meta: {},
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'status_name', label: 'Name', thClass: 'text-left', tdClass: 'text-left',
        },
        {
          key: 'order_count', label: 'Order Count', thClass: 'text-left', tdClass: 'text-left',
        },
      ],
      items: [
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchDataForMonthlySales()
      },
    },
    deep: true,
    page() {
      this.fetchDataForMonthlySales()
    },
    perPage() {
      this.fetchDataForMonthlySales()
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.fetchDataForMonthlySales()
  },
  methods: {
    async fetchDataForMonthlySales() {
      this.dataLoading = true
      this.items = []
      try {
        const { data } = (await ReportRepository.getbranchStatusCountReport(this.page, this.filterQuery, this.perPage, this.sort))
        this.items = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    resetModal() {
      this.clearOnClick()
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    handleChangePage(page) {
      this.page = page
      this.fetchDataForMonthlySales()
    },
    filterQueryUpdate() {
      this.fetchDataForMonthlySales()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select';
@import '../../@core/scss/vue/libs/vue-flatpicker';
</style>
